#header {
  padding: 10px 0;

  #logo {
    width: 100%;
    height: 80px;
    background: url("/logo.jpg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}